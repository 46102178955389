.flipper .num {
    background: #4d4d4d none repeat scroll 0 0;
    border: 1px solid #000;
    border-radius: 4px;
    color: #fff;
    display: inline-block;
    height: 65px;
    line-height: 62px;
    margin: 0 4.5px;
    position: relative;
    text-align: center;
    top: -1px;
    width: 50px;
    font-size: 45px;
    font-size: 3.8em;
    font-weight: 700;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.45);
    font-family: "Open Sans", sans-serif;
}
.flipper .num::before {
     background: #888;
     content: "";
     display: block;
     height: 1px;
     left: -1px;
     margin: 0;
     position: absolute;
     right: -1px;
     top: 50%;
     width: auto;
}