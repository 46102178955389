/* Header Style Start*/
.Header {
    background-color: #090d2b99 !important;
    border-bottom: 1px solid white;
    height: 5rem !important;
    backdrop-filter: blur(20px);
}

.Header .main-token-info {
    font-size: 1rem !important;
    color: var(--light) !important;
    font-family: PixeloidSansBold !important;
    text-transform: uppercase;
    margin-right: 1rem;
}

.Header .coin-assets {
    font-size: 1rem !important;
    color: var(--light) !important;
    font-family: PixeloidSansBold !important;
    text-transform: uppercase;
    margin-right: 1rem;
}

.Header .nav-item {
    font-size: 1rem !important;
    color: var(--light) !important;
    text-transform: uppercase;
}

.user-info .user-name {
    font-size: 1rem !important;
    color: #F4D56F !important;
    font-family: PixeloidSansBold !important;
    text-transform: uppercase;
}

.offcanvas-header .btn-close {
    /* backdrop-filter: invert(1); */
    background-color: #F4D56F !important;
    opacity: 1;
}

.user-level .level-number {
    font-size: 1rem !important;
    color: #F4D56F !important;
    font-family: PixeloidSansBold !important;
}

/* Header Style End*/

/* Sidebar Style Start */
.Sidebar {
    background-color: #090d2b99 !important;
    border-right: 1px solid white !important;
    height: calc(100vh - 5rem) !important;
    position: relative;
    z-index: 1;
    backdrop-filter: blur(20px);
}

.Sidebar::-webkit-scrollbar {
    display: none;
}

.Sidebar .nav-link {
    height: 2.5rem;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 1rem;
    border-radius: 10px;
    background-image: linear-gradient(to right, transparent, transparent);
}

.Sidebar .nav-link.active {
    height: 2.5rem;
    background-image: linear-gradient(to right, var(--secondary) 10%, transparent);
    border-radius: 10px;
}

.Sidebar .nav-link:hover {
    height: 2.5rem;
    background-image: linear-gradient(to right, var(--secondary) 10%, transparent);
    border-radius: 10px;
    transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
}

.Sidebar .sidebar-image-icon {
    cursor: pointer;
    animation: springAnimation 1s linear infinite;
}

.Sidebar .sidebar-image-icon:hover {
    transform: scale(1.2);
    animation: springAnimation1 1s linear infinite;
}

@keyframes springAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    0% {
        transform: scale(1);
    }
}

@keyframes springAnimation1 {
    0% {
        transform: scale(1.1);
    }

    50% {
        transform: scale(1.2);
    }

    0% {
        transform: scale(1.1);
    }
}

.refer-friend input {
    font-size: 12px;
}

/* Sidebar Style End */

/* App Style Start */
.App .background_video {
    position: fixed;
    left: 0;
    top: 0;
    min-width: 100%;
    min-height: 100%;
    opacity: 0.5;
    z-index: 0;
}

/* App Style End */

/* Buttons Style Start */
.outline-btn {
    height: 2.5rem;
    font-size: 1rem;
    font-family: PixeloidSansBold !important;
    color: var(--light) !important;
    background-color: transparent !important;
    text-transform: uppercase;
}

.main-btn {
    height: 2.5rem;
    font-size: 1rem;
    font-family: PixeloidSansBold !important;
    color: var(--primary) !important;
    background-color: var(--secondary) !important;
    text-transform: uppercase;
    border-color: var(--secondary) !important;
}

/* Buttons Style End */

/* Footer Style Start */
.Footer {
    background: #006b9599 !important;
    width: 100%;
    height: 3.75rem;
    backdrop-filter: blur(20px);
}

/* Footer Style End */

/* Main-content style Start */
.main-content {
    height: calc(100vh - 5rem) !important;
    overflow-y: scroll !important;
    width: 100%;
}

.Footer .footer-center-btn {
    top: -20px;
    color: var(--primary) !important;
}

.Footer .footer-center-btn::after {
    content: "";
    width: 70px;
    height: 70px;
    background-color: #00bf15 !important;
    position: absolute;
    border-radius: 50%;
    top: -10px;
    left: -50%;
}

/* Main-content style End */

/* Home Main Card Style Start */
.home-main-card .card-title {
    color: var(--secondary) !important;
    font-size: 2.25rem !important;
    text-transform: uppercase;
    font-family: PixeloidSansBold !important;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

@media screen and (max-width: 768px) {
    .home-main-card .card-title {
        color: var(--secondary) !important;
        font-size: 1.25rem !important;
        text-transform: uppercase;
        font-family: PixeloidSansBold !important;
        text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
        margin-bottom: 0px !important;
    }
}

/* Home Main Card Style End */

/* Month Top players Start */
.month-top-players .card-title {
    color: var(--light) !important;
    font-size: 1rem !important;
    text-transform: uppercase;
    font-family: PixeloidSansBold !important;
}

hr.gradient-hr {
    height: 2px;
    background: linear-gradient(90deg, rgba(242, 79, 9, 0.00) 2.81%, #F24F09 49.89%, rgba(242, 79, 9, 0.00) 100%);
    opacity: 1;
    border: none;
    margin-top: 0;
}

/* Month Top players End */

.section-title {
    color: var(--light) !important;
    font-size: 1.25rem !important;
    text-transform: uppercase;
    font-family: PixeloidSansBold !important;
}


/* CrashGameCard Style Start */
.CrashGameCard {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 30px;
    transition: 0.5s;
}

.CrashGameCard:hover:before,
.CrashGameCard:hover:after {
    transform: skewX(0deg);
    left: 20px;
    width: calc(100% - 90px);

}

.CrashGameCard:nth-child(1):before,
.CrashGameCard:nth-child(1):after {
    background: linear-gradient(315deg, #ffbc00, #ff0058)
}

.CrashGameCard span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    pointer-events: none;
}

.CrashGameCard span::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
    opacity: 0;
    transition: 0.1s;
    animation: animate 2s ease-in-out infinite;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08)
}

.CrashGameCard:hover span::before {
    top: 10px;
    left: 10px;
    width: 50px;
    height: 50px;
    opacity: 1;
}

.CrashGameCard span::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
    opacity: 0;
    transition: 0.5s;
    animation: animate 2s ease-in-out infinite;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
    animation-delay: -1s;
}

.CrashGameCard:hover span:after {
    bottom: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    opacity: 1;
}

@keyframes animate {

    0%,
    100% {
        transform: translateY(10px);
    }

    50% {
        transform: translate(-10px);
    }
}

.CrashGameCard .card-body {
    position: relative;
    left: 0;
    padding: 20px 40px;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 1;
    transform: 1s;
    color: #fff;
}

.CrashGameCard:hover .card-body {
    scale: 0.8;
    transition: 1s ease-in;
}

.CrashGameCard .card-title {
    color: var(--light) !important;
    font-size: 2rem !important;
    text-transform: uppercase;
    font-family: PixeloidSansBold !important;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.CrashGameCard:hover {
    background-size: auto !important;
    transition: 2s ease-in-out;
    box-shadow: 0 0 20px 0 white !important;
}

.CrashGameCard:hover img {
    scale: 1.1;
    transition: 1s;
    transform: rotate(360deg);
}

/* CrashGameCard Style End */

/* LatestBet Table Style Start */
.LatestBetTable thead {
    color: var(--light) !important;
    font-size: 1.2rem !important;
    text-transform: uppercase;
    font-family: PixeloidSansBold !important;
}

.LatestBetTable tbody tr:last-child td {
    border: none;
}

/* LatestBet Table Style End */

/* Signup Modal style Start */
.SignUpModal .modal-title {
    color: var(--light) !important;
    font-size: 1.25rem !important;
    text-transform: uppercase;
    font-family: PixeloidSansBold !important;
}

.SignUpModal .main-btn {
    width: 100%;
    border: none !important;
}

.SignUpModal .form-check-input {
    border-radius: 1rem !important;
    border-color: var(--light) !important;
    background-color: var(--dark) !important;
}

.SignUpModal strong {
    font-size: 1rem !important;
    text-transform: uppercase;
    font-family: PixeloidSansBold !important;
    cursor: pointer;
}

/* Signup Modal style End */

/* Form Style start */
.form-control::placeholder {
    color: var(--light) !important;
}

/* Form Style END */

/* Deposit Modal Style Start */
.DepositModal .nav-pills .nav-link.active {
    background-image: linear-gradient(to right, var(--secondary) 10%, transparent);
    border-radius: 10px;
    background-color: transparent;
    padding-left: 1rem;
}

.DepositModal .nav-pills .nav-item a {
    height: 2.5rem !important;
    background-color: transparent;
    font-size: 1rem !important;
    text-transform: uppercase;
    font-family: PixeloidSansBold !important;
    padding-left: 1rem;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.DepositModal .form-control {
    font-size: 1rem !important;
    text-transform: uppercase;
    font-family: PixeloidSansBold !important;
    padding-left: 1rem;
    color: var(--light);
}

.DepositModal .nav-item button {
    font-size: 1rem !important;
    text-transform: uppercase;
    font-family: PixeloidSansBold !important;
    padding-left: 1rem;
    color: var(--light);
}

.DepositModal .nav-item button.active {
    font-size: 1rem !important;
    text-transform: uppercase;
    font-family: PixeloidSansBold !important;
    padding-left: 1rem;
    color: var(--light);
    background-color: var(--primary) !important;
    border: none !important;
    border-bottom: 1px solid red !important;
}

/* Deposit Modal Style End */

/* Currency-Selector Style Start */
.Currency-selector>div {
    background: none;
    border-radius: 50px;
}

.Currency-selector .currency-label {
    font-size: 1rem !important;
    text-transform: uppercase;
    font-family: PixeloidSansBold !important;
    color: var(--light);
}

/* Currency-Selector Style End */

/* DepositCard Style Start */
.DepositCard .card-title {
    font-size: 1rem !important;
    text-transform: uppercase;
    font-family: PixeloidSansBold !important;
    color: var(--light);
}

.DepositCard .badge {
    font-family: PixeloidSans !important;
    font-size: 10px !important;
}

/* DepositCard Style End */

/* Mysterybox Style Start */
.MysteryBoxCard:hover .card-body img {
    scale: 1.05;
    transition: 0.5s ease-in;
    transform: rotate(15deg);
}

.MysteryBoxCard:hover .card-body {
    scale: 1.2;
    transition: 0.5s ease-in;
    transform: rotate(-15deg);
    border-radius: 50% !important;
    background-color: #58ff003d;
    backdrop-filter: blur(70px);
}

/* Mysterybox Style End */

/* Preloader Style Start */
section#loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: var(--primary);
    z-index: 9999;
    position: fixed;
    width: 100vw;
}

section#loader #preload {
    position: relative;
    width: 120px;
    height: 120px;
}

section#loader #preload span {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: rotate(calc(18deg * var(--i)));
}

section#loader #preload span::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    background-color: var(--secondary);
    box-shadow: 0 0 10px var(--secondary), 0 0 20px var(--secondary), 0 0 40px var(--secondary),
        0 0 60px var(--secondary), 0 0 80px var(--secondary), 0 0 100px var(--secondary);
    /* box-shadow: 0 0 10px #00c7fc; */
    border-radius: 50%;
    -webkit-animation: change-size 1s linear infinite;
    animation: change-size 1s linear infinite;
    -webkit-animation-delay: calc(0.05s * var(--i));
    animation-delay: calc(0.05s * var(--i));
    transform: scale(0.2);
}

@-webkit-keyframes change-size {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes change-size {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

/* Preloader Style End */

/* Jackpot Card Effect Style Start */
.jackpot {
    position: relative;
    background: linear-gradient(0deg, #000, #272727);
}

.jackpot:before,
.jackpot:after {
    content: '';
    position: absolute;
    left: -2px;
    top: -2px;
    background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000, #fb0094,
            #0000ff, #00ff00, #ffff00, #ff0000);
    background-size: 400%;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -1;
    animation: steam 20s linear infinite;
    border-radius: 1rem;
}

@keyframes steam {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}

.jackpot:after {
    filter: blur(50px);
}

/* Jackpot Card Effect Style End */

/* Trading Page Style Start */
.SettingPage .nav-tabs .nav-item button,
.GamePage .nav-tabs .nav-item button,
.TradingPage .nav-tabs .nav-item button {
    color: var(--light) !important;
    font-size: 14px !important;
    text-transform: uppercase;
    font-family: PixeloidSansBold !important;
    border-style: solid;
    /* border: none !important; */
    border-bottom: 2px solid transparent;
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
}

.SettingPage .nav-tabs .nav-item button[aria-selected="true"],
.GamePage .nav-tabs .nav-item button[aria-selected="true"],
.TradingPage .nav-tabs .nav-item button[aria-selected="true"] {
    background: none !important;
    /* border: none !important; */
    border-style: solid;
    border-bottom: 2px solid;
}

.TradingPage .group-title {
    color: var(--light) !important;
    font-size: 10px !important;
    text-transform: uppercase;
    font-family: PixeloidSans !important;
}

.NFTPrediction .down-btn,
.TradingPage .down-btn {
    font-size: 12px !important;
    color: var(--light) !important;
    background-color: #ff4949 !important;
    border-color: white !important;
    text-transform: uppercase;
    font-family: PixeloidSansBold !important;
}

.NFTPrediction .up-btn,
.TradingPage .up-btn {
    font-size: 12px !important;
    color: var(--primary) !important;
    background-color: #AAFF00 !important;
    border-color: white !important;
    text-transform: uppercase;
    font-family: PixeloidSansBold !important;
}

.TradingPage .input-group button {
    font-size: 10px !important;
    color: var(--light) !important;
    text-transform: uppercase;
    font-family: PixeloidSansBold !important;
}

.TradingPage .input-group .form-control:focus {
    border: none;
    box-shadow: none !important;
}

.TradingPage .bust_price {
    font-size: 12px !important;
}

.TradingPage .tab-pane .main-btn {
    width: 100%;
}

.TradingPage .payout-range::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    /* Override default look */
    appearance: none;
    margin-top: -12px;
    /* Centers thumb on the track */
    background-color: #5cd5eb;
    height: 2rem;
    width: 1rem;
}

.TradingPage .dropdown-menu.show {
    max-height: 50vh;
    overflow-y: scroll;
}

.token_trade_dropdown .token_name {
    font-size: 1rem !important;
    color: var(--light) !important;
    text-transform: uppercase;
    font-family: PixeloidSansBold !important;
}

.token_trade_dropdown .token_price {
    font-size: 10px !important;
    color: var(--light) !important;
    text-transform: uppercase;
    font-family: PixeloidSansBold !important;
}

.chart_option {
    font-size: 1rem !important;
    color: var(--light) !important;
    text-transform: uppercase;
    font-family: PixeloidSansBold !important;
}

.payout_range {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    background: #d3d3d3;
    background: linear-gradient(270deg, #F24F09 0%, #FAFF00 51.56%, #58FF00 100%);
    outline: none;
    opacity: 1;
    -webkit-transition: .2s;
    transition: opacity .2s;
    border-radius: 50px;
}

.payout_range:hover {
    opacity: 1;
}

.payout_range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: var(--warning);
    cursor: pointer;
    border-radius: 50px;
    border: 2px solid var(--primary);
}

.payout_range::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: var(--warning);
    cursor: pointer;
    border-radius: 50px;
}

.payout_range_body strong.safe_color {
    color: #58FF00;
    font-family: PixeloidSansBold !important;
    font-size: 10px;
    text-transform: uppercase;
}

.payout_range_body strong.wild_color {
    color: #F24F09;
    font-family: PixeloidSansBold !important;
    font-size: 10px;
    text-transform: uppercase;
}

.payout_range_body span {
    font-size: 10px !important;
}

@keyframes infiniteScroll {
    from {
        transform: translateX(0)
    }

    to {
        transform: translateX(-100%)
    }
}

.horizontal-scrolling-items {
    display: flex;
    font-size: 12px;
    width: 200%;
    animation-name: infiniteScroll;
    animation-duration: 60s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.horizontal-scrolling-items__item {
    white-space: nowrap;
}

.fast-chart-sm-button {
    border: 1px solid #F4D56F !important;
    color: #F4D56F !important;
    font-size: 1rem !important;
    font-family: PixeloidSansBold !important;
}

.fast-chart-sm-button:hover,
.fast-chart-sm-button.active {
    background: #F4D56F !important;
    color: var(--primary) !important;
}

.fast-chart-down-btn,
.fast-chart-up-btn {
    color: var(--primary) !important;
    font-size: 1.25rem !important;
    font-family: PixeloidSansBold !important;
}

.fast-chart-down-btn:disabled,
.fast-chart-up-btn:disabled {
    animation: none;
}

@keyframes glowing {
    0% {
        background-color: #72f238;
        box-shadow: 0 0 3px #72f238;
    }

    50% {
        background-color: #72f238;
        box-shadow: 0 0 40px #72f238;
    }

    100% {
        background-color: #72f238;
        box-shadow: 0 0 3px #72f238;
    }
}

@keyframes glowing1 {
    0% {
        background-color: #ff4949;
        box-shadow: 0 0 3px #ff4949;
    }

    50% {
        background-color: #ff4949;
        box-shadow: 0 0 40px #ff4949;
    }

    100% {
        background-color: #ff4949;
        box-shadow: 0 0 3px #ff4949;
    }
}

.fast-chart-down-btn {
    background: #ff4949 !important;
    animation: glowing1 1500ms infinite;
}

.fast-chart-up-btn {
    background: #72f238 !important;
    animation: glowing 1500ms infinite;
    border-color: #72f238 !important;
}

.FastAndFuriousChart .fast-chart-info {
    color: #F4D56F !important;
}

.FastAndFuriousChart .fast-chart-info .fast-chart-info-value {
    color: #F4D56F;
    font-size: 1.5rem;
    font-family: PixeloidSansBold !important;
}

.FastAndFuriousChart .fast-chart-info .fast-chart-info-value.down-pool {
    color: #ff4949 !important
}

.FastAndFuriousChart .fast-chart-info .fast-chart-info-value.up-pool {
    color: #72f238 !important
}

.fast-chart-info-down-payout {
    color: #C2060D !important;
    font-size: 3rem !important;
    font-family: PixeloidSansBold !important;
}

.fast-chart-info-up-payout {
    color: #58FF00 !important;
    font-size: 3rem !important;
    font-family: PixeloidSansBold !important;
}

.fast-chart-coin-dropdown span {
    color: var(--light) !important;
    font-size: 1.5rem !important;
    font-family: PixeloidSansBold !important;
}

.fast-chart-count-down {
    left: 33%;
    z-index: 100;
}

.NFTPrediction .timer.duration-time .value {
    color: #ec0000 !important;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}

.NFTPrediction .timer .value,
.fast-chart-count-down .timer .value {
    font-size: 2.5rem !important;
    font-family: PixeloidSansBold !important;
    color: #F24F09 !important;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
    transform: scale(1);
    -webkit-animation: scaleAnimation 0.5s ease-in-out infinite alternate;
}

@keyframes scaleAnimation {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1.5);
    }
}

.fast-chart-count-down .timer .text {
    font-size: 1.25rem !important;
    color: #F4D56F !important;
}

@media screen and (max-width: 768px) {

    .fast-chart-info-up-payout,
    .fast-chart-info-down-payout {
        font-size: 2rem !important;
    }

    .fast-chart-count-down {
        left: -15%;
    }

    .fast-chart-sm-button {
        font-size: 0.75rem !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        text-align: center;
    }
}

/* Trading Page Style End */

.section-sub-title {
    font-size: 1.25rem !important;
    font-family: PixeloidSansBold !important;
}

/* Setting Page Style Start */
.SettingPage .form-control::placeholder {
    color: #ffffff86 !important;
}

.SettingPage .react-tel-input {
    background: var(--primary) !important;
    border: 1px solid white;
    border-radius: 50px !important;
    /* overflow: hidden; */
}

.SettingPage .react-tel-input .form-control,
.SettingPage .react-tel-input .flag-dropdown {
    background: transparent !important;
    border: none !important;
}

.react-tel-input .country-list {
    background: var(--primary) !important;
}

.react-tel-input .country-list .country:hover {
    background-color: #020C57 !important;
}

.react-tel-input .selected-flag {
    background: transparent !important;
}

.SettingPage .react-tel-input .form-control:focus {
    box-shadow: none !important;
}

.react-tel-input .country-list .country.highlight {
    background-color: #020C57 !important;
}

.react-select__menu .react-select__option {
    background: var(--primary) !important;
}

.css-13cymwt-control,
.css-t3ipsp-control,
.css-1nmdiq5-menu {
    background-color: var(--primary) !important;
    border-color: white !important;
}

.css-13cymwt-control,
.css-t3ipsp-control {
    border-radius: 50px !important;
}

.css-1dimb5e-singleValue,
.css-qbdosj-Input,
.css-166bipr-Input {
    color: white !important;
}

.css-1nmdiq5-menu div>div {
    background: var(--primary) !important;
}

.css-1nmdiq5-menu div>div:hover {
    background-color: #020C57 !important;
}

.css-13cymwt-control:hover {
    border-color: white !important;
}

.SettingPage .main-btn {
    width: 100%;
}

.SettingPage .deposit-btn {
    font-family: PixeloidSansBold !important;
    text-transform: uppercase;
}

.SettingPage .withdraw-btn {
    font-family: PixeloidSansBold !important;
    text-transform: uppercase;
    color: var(--primary) !important;
}

.SettingPage .table {
    font-size: 1rem !important;
    font-family: PixeloidSansBold !important;
    text-transform: uppercase;
}

/* Setting Page Style End */

/* NFTTrade Page Style Start */
.NFTPrediction .nfttrade-info .title,
.NFTPrediction .nfttrade-info .price {
    font-size: 1.25rem !important;
    font-family: PixeloidSansBold !important;
    text-transform: uppercase;
}

.NFTPrediction .nft-trading-transaction {
    position: absolute;
    bottom: 1rem;
    width: 95%;
    flex-wrap: wrap;
}

/* NFTTrade Page Style End */

/* FreeSpinWheel Style Start */
/* Conteneur de la roue = cercle rose extérieur */
.wheel-container {
    /* Vars */
    --wheel-font: 'Lato', 'Quicksand', sans-serif;
    --wheel-size: 300px;
    --wheel-slice-spacing: 50px;
    --wheel-border-size: 5px;
    --wheel-color: #ffffff;
    /* --neutral-color: #0399ff; */
    --neutral-color: #da03ff;
    --PI: 3.14159265358979;
    --nb-item: 0;
    --item-nb: 0;
    --selected-item: 0;
    --nb-turn: 5;
    --spinning-duration: 4s;
    --reset-duration: 0.25s;

    display: block;
    position: relative;
    box-sizing: content-box;
    width: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
    height: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
    padding: 3px;
    margin: auto;
    background-color: var(--neutral-color);
    border: solid var(--wheel-color) 3px;
    border-radius: 50%;
    user-select: none;
}

/* Triangle de sélection = 1 rose +  1 blanc */
.wheel-container::before,
.wheel-container::after {
    content: '';
    display: block;
    position: absolute;
    height: 0;
    width: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    border: solid transparent 20px;
    border-left-width: 0;
}

/* .wheel-container::before {
    right: 0px;
    border-right-color: var(--wheel-color);
  } */

/* .wheel-container::after {
    right: -5px;
    border-right-color: var(--neutral-color);
  } */

/* Roue */
.spin-wheel {
    display: block;
    position: relative;
    box-sizing: content-box;
    margin: auto;
    width: var(--wheel-size);
    height: var(--wheel-size);
    overflow: hidden;
    border-radius: 50%;
    border: solid var(--wheel-color) var(--wheel-border-size);
    background-color: var(--wheel-color);
    transition: transform var(--reset-duration);
    transform: rotate(0deg);
    cursor: pointer;
}

.spin-wheel.spinning {
    transition: transform var(--spinning-duration);
    transform: rotate(calc(var(--nb-turn) * 360deg + (-360deg * var(--selected-item) / var(--nb-item, 1))));
}

/* Centre de la roue = rond blanc au centre */
.spin-wheel::after {
    display: block;
    position: absolute;
    content: '';
    background-color: white;
    width: 50px;
    height: 50px;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    box-shadow: 0 0 30px 0 black;
}

/* Element sur la roue */
.wheel-item {
    display: block;
    position: absolute;
    box-sizing: border-box;

    /* position de l'item */
    top: 50%;
    left: 50%;
    width: 50%;
    transform-origin: center left;
    transform: translateY(-50%) rotate(calc(var(--item-nb) * (360deg / var(--nb-item, 1))));

    /* texte */
    color: white;
    text-align: right;
    padding: 0 25px 0 50px;
    font-family: var(--wheel-font);
}

/* Background de l'élément = triangle rose plus clair */
.wheel-item:before {
    content: ' ';
    display: block;
    position: absolute;
    box-sizing: border-box;
    z-index: -1;
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-left: 0px;
    opacity: 1;

    /* 
      Largeur du triangle
      Je ne sais pas pourquoi j'ai besoin d'ajouter 
        " + var(--wheel-size) / 2 " dans slice-max-width  ==> donne 2.PI.R + R
      mais globalement ca marche a peu près bien 
      TODO: A étudier 
    */
    --slice-max-width: calc(var(--PI) * var(--wheel-size) + var(--wheel-size) / 2);
    --slice-width: calc((var(--slice-max-width) / var(--nb-item)) - var(--wheel-slice-spacing));
    border: solid transparent calc(var(--slice-width) / 2);
    border-left: solid transparent 0;
    /* profondeur du triangle = du centre de la roue à la bordure = 300px / 2 */
    border-right: solid var(--neutral-color) calc(var(--wheel-size) / 2);
}

img.layout {
    position: absolute;
    top: -65px;
    left: -70px
}

.item-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* FreeSpinWheel Style End */

/* Level Badge Style Stat */
.level-badge {
    position: relative;
    margin: 1.5em 3em;
    width: 4em;
    height: 6.2em;
    border-radius: 10px;
    display: inline-block;
    top: 0;
    transition: all 0.2s ease;
}

.level-badge:before,
.level-badge:after {
    position: absolute;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    background: inherit;
    content: "";
}

.level-badge:before {
    transform: rotate(60deg);
}

.level-badge:after {
    transform: rotate(-60deg);
}

.level-badge:hover {
    top: -4px;
}

.level-badge .circle {
    width: 60px;
    height: 60px;
    position: absolute;
    background: #fff;
    z-index: 10;
    border-radius: 50%;
}

.level-badge .circle i.fa {
    font-size: 2em;
    margin-top: 8px;
}

.level-badge .font {
    display: inline-block;
    margin-top: 1em;
}

.level-badge .ribbon {
    position: absolute;
    border-radius: 4px;
    padding: 5px 5px 4px;
    width: 100px;
    z-index: 11;
    color: #fff;
    bottom: 12px;
    left: 50%;
    margin-left: -55px;
    height: 15px;
    font-size: 14px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.27);
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    text-transform: uppercase;
    text-align: center;
    background: linear-gradient(to bottom right, #555 0%, #333 100%);
    cursor: default;
}

.yellow {
    background: linear-gradient(to bottom right, #ffeb3b 0%, #fbc02d 100%);
    color: #ffb300;
}

.orange {
    background: linear-gradient(to bottom right, #ffc107 0%, #f57c00 100%);
    color: #f68401;
}

.pink {
    background: linear-gradient(to bottom right, #F48FB1 0%, #d81b60 100%);
    color: #dc306f;
}

.red {
    background: linear-gradient(to bottom right, #f4511e 0%, #b71c1c 100%);
    color: #c62828;
}

.purple {
    background: linear-gradient(to bottom right, #ab47bc 0%, #4527a0 100%);
    color: #7127a8;
}

.teal {
    background: linear-gradient(to bottom right, #4DB6AC 0%, #00796B 100%);
    color: #34a297;
}

.blue {
    background: linear-gradient(to bottom right, #4FC3F7 0%, #2196F3 100%);
    color: #259af3;
}

.blue-dark {
    background: linear-gradient(to bottom right, #1976D2 0%, #283593 100%);
    color: #1c68c5;
}

.green {
    background: linear-gradient(to bottom right, #cddc39 0%, #8bc34a 100%);
    color: #7cb342;
}

.green-dark {
    background: linear-gradient(to bottom right, #4CAF50 0%, #1B5E20 100%);
    color: #00944a;
}

.silver {
    background: linear-gradient(to bottom right, #E0E0E0 0%, #BDBDBD 100%);
    color: #9e9e9e;
}

.gold {
    background: linear-gradient(to bottom right, #e6ce6a 0%, #b7892b 100%);
    color: #b7892b;
}

/* Level Badge Style End */

/* AmCharts Badge Style Start */
.chart-badge {
    display: flex;
    align-items: center;
    /* background-color: white; */
    border-radius: 2.5rem;
    border: 3px solid #F4D56F;
    padding: 0.3rem;
    background: #090d2b99 !important;
    /* background-color: #090d2b !important; */
    /* border: 3px solid white !important; */
    position: relative;
    z-index: 1;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
}

.chart-badge .image {
    width: 3rem;
    height: 3rem;
    text-align: center;
    border: 3px solid #F4D56F;
    border-radius: 50%;
    box-shadow: 2px 2px 8px 0 gray;
    margin-right: 0.5rem;
    background-color: white;
}

.chart-badge .image img {
    height: 100% !important;
}

.chart-badge .content {
    text-align: center;
    padding-right: 0.5rem;
}

.chart-badge .content .name {
    color: #F4D56F;
    font-family: PixeloidSansBold;
}

.asset-list-selector-img {
    font-family: PixeloidSansBold;
    text-align: center;
    font-weight: 100;
    color: #00ff00;
    font-size: 1.25rem;
    transform: scale(0.9);
    -webkit-animation: fontsize .5s ease-in-out infinite alternate;
}

@keyframes fontsize {
    from {
        transform: scale(0.9);
    }

    to {
        transform: scale(1.05);
    }
}

/* AmCharts Badge Style End */

.bankroll {
    font-family: PixeloidSansBold;
}

.ac-container {
    display: none !important;
}

.am5-layer-30 {
    display: none !important;
}

.selected-asset-price {
    font-family: PixeloidSansBold;
    font-size: 1.5rem;
}

.fast-chart-info-value .dropdown-toggle,
.fast-chart-info-value .dropdown-toggle:hover {
    color: #F4D56F;
    font-size: 1.5rem;
    font-family: PixeloidSansBold !important;
}

.asset-list-component {
    position: absolute !important;
    bottom: 0%;
    background: #090d2bcc !important;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
}

.asset-list-selector.dropdown-toggle::after {
    display: none !important;
}


/* .chart-multiplier {
    font-size: 3rem;
    font-family: PixeloidSansBold;
    color: white !important;
    -webkit-animation: fontsize1 .5s ease-in-out infinite alternate;
} */

@keyframes fontsize1 {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1.5);
    }
}

.progress-bar {
    /* background-color: #2cdc508f !important; */
}

@media screen and (max-width: 768px) {
    .chart-multiplier {
        font-size: 1rem !important;
    }
}

.rw-wrapper {
    width: 80%;
    position: relative;
    margin: 0px auto 0 auto;
    padding: 10px;
}

.rw-sentence {
    margin: 0;
}

.rw-sentence span {
    text-align: center;
    color: rgba(255, 255, 255, 1);
    font-family: 'Roboto Condensed', sans-serif;
    white-space: nowrap;
    text-shadow: 2px 5px 10px rgba(0, 0, 0, 0.1);
}

.rw-sentence>span {
    position: absolute;
}

.rw-words span {
    font-family: impact;
    position: absolute;
    font-size: 800%;
    color: transparent;
    text-shadow: 0px 0px 80px rgba(255, 255, 255, 1);
    opacity: 0;
    -webkit-animation: rotateWord 5s linear infinite 0s;
    -ms-animation: rotateWord 5s linear infinite 0s;
    animation: rotateWord 5s linear infinite 0s;
}

@keyframes rotateWord {
    0% {
        opacity: 0;
        animation-timing-function: ease-in;
        transform: translateY(-200px) translateZ(300px) rotateY(-120deg);
    }

    5% {
        opacity: 1;
        animation-timing-function: ease-out;
        -webkit-transform: translateY(0px) translateZ(0px) rotateY(0deg);
        transform: translateY(0px) translateZ(0px) rotateY(0deg);

    }


    6% {
        text-shadow: 0px 0px 0px rgba(255, 255, 255, 1);
        color: #fff;
    }

    17% {
        opacity: 1;
        text-shadow: 0px 0px 0px rgba(255, 255, 255, 1);
        color: #fff;
    }

    20% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

.no-cssanimations .rw-words span:first-child {
    opacity: 1;
    color: #fff;
    text-shadow: none;
}


.social {
    position: fixed;
    bottom: 10px;
    right: 10px;
    background: #fff;
    font-family: verdana;
    padding: 5px;
    border-radius: 5px;
    animation: new .5s linear infinite;
    margin-top: -50px;
}

.social a {
    text-decoration: none;
    color: black;
    font-weight: bold;
}

@keyframes new {
    0% {
        transform: scaleX(1);
    }

    50% {
        transform: scaleX(.95);
    }

    100% {
        transform: scaleX(1);
    }
}


.selected-asset-label {
    color: #F4D56F;
    font-size: 1.5rem;
    font-family: PixeloidSansBold !important;
}


.fast-chart-info-value.down-pool {
    -webkit-animation: new1 1s ease-in-out infinite;
}

.fast-chart-info-value.up-pool {
    -webkit-animation: new2 1s ease-in-out infinite;
    animation-delay: 0.5s;
}

@keyframes new1 {
    0% {
        transform: scale(0);
        /* transform: scaleY(1); */
    }

    50% {
        transform: scale(1.2);
        /* transform: scaleY(1); */
    }

    100% {
        transform: scale(1);
        /* transform: scaleY(1.5); */
    }
}

@keyframes new2 {
    0% {
        transform: scale(0);
        /* transform: scaleY(1); */
    }

    50% {
        transform: scale(1.2);
        /* transform: scaleY(1); */
    }

    100% {
        transform: scale(1);
        /* transform: scaleY(1.5); */
    }
}

.fast-chart-info-value.down-pool {
    text-shadow: black 1px 1px, #b03c46 2px 2px, #b03c46 3px 3px, #b03c46 4px 4px, #b03c46 5px 5px, #b03c46 6px 6px, #b03c46 7px 7px, #b03c46 8px 8px, #b03c46 9px 9px, #b03c46 10px 10px, #b03c46 11px 11px;
}

.fast-chart-info-value.up-pool {
    text-shadow: black 1px 1px, #56a635 2px 2px, #56a635 3px 3px, #56a635 4px 4px, #56a635 5px 5px, #56a635 6px 6px, #56a635 7px 7px, #56a635 8px 8px, #56a635 9px 9px, #56a635 10px 10px, #56a635 11px 11px;
}

.up-trade-btn {
    background: #58FF0066 !important;
}

.down-trade-btn {
    background: #FF544966 !important;
}

.down-players,
.up-players {
    transition: ease-in-out 1s;
}

.detrade-chart .w-60.transition-all.duration-300.bg-layer3.flex.flex-col {
    display: none !important;
}

.detrade-chart iframe header {
    display: none !important;
}