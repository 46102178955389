.DegenSlotGame {
    font-family: RifficFreeBold !important;
    color: white !important;
}

.DegenSlotGame .game.card {
    border: 1rem solid #D4145A;
    background-image: url('../../../../assets/images/games/degen/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.DegenSlotGame .slot-body.card {
    border: 0.5rem solid #D4145A;
    box-shadow: 0 0 0 0 black;
    background-image: linear-gradient(#5B037A, white, #5B037A);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 350px;
}

.DegenSlotGame .controllers {
    background-color: var(--primary);
}

.DegenSlotGame .controllers .balance-info {
    font-size: 1.25rem !important;
}

.DegenSlotGame .controllers .form-label {
    font-size: 1.5rem;
}

.DegenSlotGame .controllers .form-control {
    font-size: 1.5rem;
}

.slot-body .stage {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
}

.slot-body .ring,
.slot-body .item,
.slot-body .console-outer {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    user-select: none;
}

.slot-body .row.console {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.slot-body .console-outer {
    /* max-height:500px;
    min-height: 250px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    margin: auto;
    gap: 2px;
    border-left: none;
    border-right: none;
    border-radius: 15px; */
    max-height: 500px;
    min-height: 340px;
    flex: 1 1 auto;
    /* box-shadow: 0 5px 20px 0px #07352b80; */
    display: flex;
    flex-direction: row;
    /* max-width: 53vw; */
    /* width: 50vw; */
    margin: auto;
    margin-bottom: 20px;
    gap: 2px;
    /* border: 15px solid black; */
    border-left: none;
    border-right: none;
    border-radius: 15px;
}

.slot-body .col.wheel {
    background-image: linear-gradient(#4b0066, #bc3deb, #4b0066);
    min-height: 340px;
    min-height: 340px;
    overflow: hidden;
    margin: auto;
    flex: 0 0 calc(20% - 2px);
    max-width: calc(20% - 2px);
    position: relative;
}


.slot-body .container {
    perspective: 2000px;
    width: 90%;
    height: 130px;
    left: 0%;
    top: 50%;
    transform: translate(5%, -50%);
    position: relative;
    padding: 0;
}

.slot-body ul {
    list-style: none;
    padding: 0;
}

.slot-body .item {
    display: flex;
    justify-content: center;
    vertical-align: center;
    margin: auto;
    border: 1px solid white;
    border-radius: 5px;
    backface-visibility: hidden;
    background: white;
    opacity: 0.9;

}

.slot-body .item span {
    font-size: 2.5em;
    margin: auto;
}

.slot-body .item.active {
    box-shadow: inset 0px 0 10px 0px #ecd820b0;
    opacity: 1;
}

.stage svg,
.stage ul,
.stage li,
.stage .container {
    position: absolute;
}