:root {
  --primary: #090D2B;
  --secondary: #58FF00;
  --success: #090D2B;
  --info: #090D2B;
  --warning: #F24F09;
  --danger: #090D2B;
  --light: #FFFFFF;
  --dark: #010023;
}

@font-face {
  font-family: PixeloidSans;
  src: url('./assets/fonts/PixeloidSans.ttf');
}

@font-face {
  font-family: PixeloidSansBold;
  src: url('./assets/fonts/PixeloidSans-Bold.ttf');
}

@font-face {
  font-family: RifficFreeBold;
  src: url('./assets/fonts/RifficFree-Bold.ttf');
}

body {
  font-family: PixeloidSans !important;
  font-size: 14px !important;
  background-color: var(--primary) !important;
}

.bg-primary-1 {
  background-color: var(--primary) !important;
}

.bg-primary-2 {
  background-color: #090d2b99 !important;
  border-right: 1px solid white !important;
  position: relative;
  z-index: 1;
  backdrop-filter: blur(20px);
}

.bg-warning-1 {
  background: var(--warning) !important;
}

.bg-secondary-1 {
  background: #58FF00 !important
}

.bg-secondary-2 {
  background: #59ff0075 !important
}

.text-primary-1 {
  color: var(--primary) !important;
  font-family: PixeloidSansBold !important;
}

.modal-backdrop.show {
  background: rgba(9, 13, 43, 1) !important;
  backdrop-filter: blur(20px);
  opacity: 0.9 !important;
}

.border-secondary-1 {
  border-color: #58FF00 !important
}

.border-secondary-2 {
  border-color: #f5cd48 !important
}

.text-secondary-1 {
  color: var(--secondary) !important;
}

/* Daily Jackpot Style Start */
.timer-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.timer {
  font-size: 46px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: PixeloidSansBold;
}

.timer span {
  font-size: 16px;
  color: #444;
}

.semicolon {
  font-size: 36px;
}

/* Daily Jackpot Style End */

onboard-v2 {
  z-index: 2000 !important;
  position: sticky !important;
}

wcm-modal {
  z-index: 3000 !important;
  position: sticky !important;
}

*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.detrade-chart .w-60.transition-all.duration-300.bg-layer3.flex.flex-col{
  display: none !important;
}











